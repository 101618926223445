import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Team } from "../components/Team";
import { teamPageHeroImage } from "../images";

const TeamPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${teamPageHeroImage})`}
      heroOverlayLeadElement="Meet the Team"
      heroOverlaySecondaryElement="Because software is created by people."
    >
      <Team />
    </PageLayout>
  );
};

export default TeamPage;
